export default (store) => {
  let defaultCurrency = config.ecommerce.currency.default;
  return {
    state: () => ({
      defaultCode: defaultCurrency,
      code: defaultCurrency,
      exchangeRatesLoaded: false,
      exchangeRates: false,
    }),
    mutations: {
      setExchangeRatesLoaded(state, payload) {
        state.exchangeRatesLoaded = payload;
      },
      set(state, payload) {
        if (typeof payload !== "string") {
          warn("store currency set - payload must be a string (currency code");
          return false;
        }
        state.code = payload.toUpperCase();
      },
    },
    actions: {
      async fetchExchangeRates(context) {
        let result = await this.asyncOperations.asyncCall(
          "ecommerce/currency/exchange-rates",
          {},
          { method: "get" }
        );

        if (result.hasError) {
          return false;
        }

        context.state.exchangeRates = result.data;
        return true;
      },
    },
    getters: {
      defaultCode(state) {
        return state.defaultCode;
      },
      code(state) {
        return state.code;
      },
      areExchangeRatesLoaded(state) {
        return !!state.exchangeRates;
      },
      exchangeRates(state) {
        return state.exchangeRates;
      },
    },
  };
};
