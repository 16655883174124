export default (store) => {
  //store.cookie.get
  // load cart ids from cookie
  // load cartId from cookie
  let cartUuidCookieName = "__SAFFRON_CART_UUID__";
  let cartUuidsCookieName = "__SAFFRON_CART_UUIDS__";
  let defaultCart = store.cookie.get(cartUuidCookieName);
  let cartUuids = store.cookie.get(cartUuidsCookieName);

  if (
    !defaultCart ||
    defaultCart === "false" ||
    defaultCart === "0" ||
    defaultCart === 0
  ) {
    defaultCart = false;
  }
  return {
    state: () => ({
      cartUuid: defaultCart ? defaultCart : false,
      cartUuids: cartUuids ? cartUuids : [],
    }),
    mutations: {
      setCartUuids(state, payload) {
        if (!Array.isArray(payload)) {
          warn(
            "carts store - setCartUuids() - expecting array payload. Not setting.",
            payload
          );
          return false;
        }
        state.cartUuids = [];

        payload.forEach((uuid) => this.commit("carts/addCartUuid", uuid));
      },
      addCartUuid(state, payload) {
        let newUuid = payload;
        if (typeof payload === "object" && payload.uuid) {
          newUuid = payload.uuid;
        }

        if (state.cartUuids.includes(newUuid)) {
          return true;
        }

        state.cartUuids.push(newUuid);
        store.cookie.set(cartUuidsCookieName, state.cartUuids);
      },
      setDefaultCartUuid(state, payload) {
        if ((payload === false)) {
          state.cartId = false;
          store.cookie.set(cartUuidCookieName, state.cartId);
          return true;
        }

        let newUuid = payload;
        if (typeof payload === "object" && payload.uuid) {
          newUuid = payload.uuid;
        }
        if (typeof newUuid !== "string" && newUuid !== false) {
          error(
            "store/carts setDefaultCartUuid() input must be a cart or string or exactly false",
            newUuid
          );
          return false;
        }

        state.cartId = newUuid;
        store.cookie.set(cartUuidCookieName, state.cartId);
      },
      forgetCart(state, payload) {
        let targetUuid = payload;
        if (typeof payload === "object" && payload.uuid) {
          targetUuid = payload.uuid;
        }

        // delete from array
        state.cartUuids = state.cartUuids.filter((item) => item !== targetUuid);
        store.cookie.set(cartUuidsCookieName, state.cartUuids);

        // if default, delete default
        if (state.cartUuid === targetUuid) {
          state.cartId = false;
          store.cookie.set(cartUuidCookieName, state.cartId);
        }
      },
    },
    actions: {},
    getters: {
      cartUuid(state) {
        return state.cartUuid;
      },
      defaultCartUuid(state) {
        return state.cartUuid;
      },
      cartUuids(state) {
        return state.cartUuids;
      },
    },
  };
};
