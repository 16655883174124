const cookies = require("js-cookie");
import _ from "lodash/object";
/*
 const plugin =  {
 that :this,
 defaultConfig : {
 expires : Number(config.cookie.expires),
 path    : config.cookie.path
 },
 install(target, options = {}) {
 let  { set, get, remove, defaultConfig} = plugin;
 plugin.defaultConfig = _.merge(defaultConfig, options);

 target.$cookie = {set, get, remove, defaultConfig};
 },
 set (key, value, config = plugin.defaultConfig) {
 return cookies.set(key, value, config);
 },
 get (key, defaultValue = null) {
 let val =  cookies.get(key);
 if (typeof val === 'undefined') {
 return defaultValue;
 }

 return val;
 },
 remove (name) {
 return cookies.remove(name);
 },
 delete (name) {
 return cookies.remove(name);
 }
 };
 */
//////////////////////

// TODO: this code is very similar to the cookie plugin for the app. try to actually use that plugin instead of this
// this is not DRY and we have to inject server cookies twice

export default (store) => {
  let injectedCookies = {};
  let injectServerCookies = (cookies) => {
    if (!cookies || typeof cookies !== "object") {
      return true;
    }

    injectedCookies = { ...cookies };
  };

  const plugin = {
    that: this,
    defaultConfig: {
      expires: Number(config.cookie.expires),
      path: config.cookie.path,
      SameSite: "None",
      Secure: true,
    },
    install(target, options = {}) {
      plugin.defaultConfig = _.merge(plugin.defaultConfig, options);
      let { set, remove, defaultConfig } = plugin;

      let getUniversal = (key, defaultValue = null) => {
        if (typeof injectedCookies[key] === "undefined") {
          return plugin.get(key, defaultValue);
        }

        let val = plugin.get(key, defaultValue);
        let injectedVal = injectedCookies[key];

        // if the value is null or default, and the injected value is not - use the indected value
        if (val === null || (val === defaultValue && injectedVal !== defaultConfig)) {
          return injectedVal;
        }

        return defaultValue;
      };

      target.cookie = {
        set,
        get: getUniversal,
        remove,
        defaultConfig,
        injectServerCookies,
      };
    },
    set(key, value, config = plugin.defaultConfig) {
      config = { ...plugin.defaultConfig, ...config };
      return cookies.set(key, value, config);
    },
    get(key, defaultValue = null) {
      let val = cookies.get(key);
      if (typeof val === "undefined") {
        return defaultValue;
      }

      if (typeof val === "string") {
        try {
          let result = JSON.parse(val);
          if (result) {
            return result;
          }
        } catch (e) {}
      }

      return val;
    },
    remove(name) {
      return cookies.remove(name);
    },
    delete(name) {
      return cookies.remove(name);
    },
  };

  let assignCookieToModuleRecursive = (module) => {
    plugin.install(module, {}, false);

    if (module._children) {
      for (const [index, childModule] of Object.entries(module._children)) {
        assignCookieToModuleRecursive(childModule);
      }
    }
  };

  plugin.install(store, {});
  assignCookieToModuleRecursive(store._modules.root);
};
