export default (store) => {
  const saffronLocaleCookieKey = "__SAFFRON__LOCALE__SLUG";

  return {
    state: () => {
      let cookieLocaleSlug = store.cookie.get(saffronLocaleCookieKey);
      let initialLanguageSlug = cookieLocaleSlug
        ? cookieLocaleSlug
        : config.locale.defaultLocale;

      return {
        slug: initialLanguageSlug,
        defaultSlug: config.locale.defaultLocale,
      };
    },
    mutations: {
      slug(state, payload) {
        state.slug = payload;
        this.cookie.set(saffronLocaleCookieKey, state.slug);
      },
    },
    actions: {},
    getters: {
      slug(state) {
        if (!state.slug || state.slug === null) {
          return state.defaultSlug;
        }

        return state.slug;
      },
      defaultSlug(state) {
        return state.defaultSlug;
      },
    },
  };
};
