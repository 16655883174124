let defaults = {
  globalSpinnerText: "core.loading",
};

export default {
  state: () => ({
    globalSpinner: {
      active: false,
      text: defaults.globalSpinnerText,
      textParams: {},
    },
  }),
  mutations: {
    showGlobalSpinner(state, payload) {
      // if null like, or excactly true - show spinner with default text
      if (
        typeof payload === "undefined" ||
        (typeof payload === "object" && payload === null) ||
        payload === true
      ) {
        state.globalSpinner.text = defaults.globalSpinnerText;
        state.globalSpinner.active = true;
        return;
      }

      if (typeof payload === "boolean" && payload === false) {
        state.globalSpinner.text = defaults.globalSpinnerText;
        state.globalSpinner.active = false;
        return;
      }

      // if string - show the spinner with string
      if (typeof payload === "string") {
        state.globalSpinner.text = payload;
        state.globalSpinner.active = true;
        return;
      }

      // deeper customization via object
      if (typeof payload === "object" && payload !== null) {
        if (payload.text) {
          state.globalSpinner.text = payload.text;
        }

        if (payload.params) {
          state.globalSpinner.textParams = payload.params;
        } else {
          state.globalSpinner.textParams = {};
        }

        if (payload.active) {
          state.globalSpinner.active = payload.active;
        }
        return;
      }

      debug(
        "commiting to ui showGlobalSpinner with an unsupported payload",
        2,
        payload
      );
      // return state.globalSpinner
    },
    hideGlobalSpinner(state, payload) {
      state.globalSpinner.text = defaults.globalSpinnerText;
      state.globalSpinner.active = false;
    },
  },
  actions: {},
  getters: {
    isGlobalSpinnerActive(state) {
      return state.globalSpinner.active;
    },
    globalSpinnerText(state) {
      return state.globalSpinner.text;
    },
    globalSpinnerTextParams(state) {
      return state.globalSpinner.textParams;
    },
    globalSpinner(state) {
      return state.globalSpinner;
    },
  },
};
